<template>
  <base-layout>
    <div class="block full">
      <div class="block-title">
        <h2>Win/Loss Reports</h2>
      </div>

      <win-loss-list></win-loss-list>
    </div>
  </base-layout>
</template>

<script>
import WinLossList from "@/components/winLossReport/ListItem";
import BaseLayout from "./layouts/Base";

export default {
  name: "WinLossReport",
  components: {
    BaseLayout,
    WinLossList
  }
};
</script>
