<template>
  <div>
    <div class="block-section">
      <div class="row">
        <div class="form-group col-md-2">
          <label for="">Game Type</label>
          <select v-model="gameType" name="type" class="form-control" size="1">
            <option value="0">Select Game</option>
            <!--<option value="2">Game Online</option>-->
            <option value="1">Casino</option>
            <option value="2">Sport Book</option>
            <option value="3">Fish Shooter</option>
            <option value="4">Slot game</option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <label for="">From Date</label>
          <datepicker
            v-model="fromDate"
            value-type="format"
            input-class="form-control"
            placeholder="Date"
            type="date"
          ></datepicker>
        </div>
        <div class="form-group col-md-2">
          <label for="">To Date</label>
          <datepicker
            v-model="toDate"
            value-type="format"
            input-class="form-control"
            placeholder="Date"
            type="date"
          ></datepicker>
        </div>
        <div class="form-group col-md-2">
          <label for="">Action</label>
          <button type="button" class="btn btn-primary" @click="handleSearch" style="display:block">
            <i class="fa fa-search"></i> Search
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table
        class="table table-vcenter table-bordered table-hover table-condensed"
        v-if="hasItem && reports"
      >
        <thead>
          <tr v-if="gameType == 1">
            <th>Match ID</th>
            <th>Game</th>
            <th>Bet Time</th>
            <th>Bet Amount</th>
            <th>Profit</th>
            <th>Balance</th>
            <th>Win/Loss</th>
          </tr>
          <tr v-if="gameType == 2">
            <th>Match ID</th>
            <th>User Name</th>
            <th>Game</th>
            <th>Bet Time</th>
            <th>Bet Amount</th>
            <th>Win/Loss</th>
          </tr>
          <tr v-if="gameType == 3">
            <th>Match ID</th>
            <th>Type</th>
            <th>Bet Time</th>
            <th>Bet Amount</th>
            <th>Profit</th>

            <th>Win/Loss</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="gameType == 1">
            <tr v-for="report in reports" :key="report.BetDetail.BetID">
              <td>{{ report.BetDetail.GameID }}</td>
              <td>{{ report.BetDetail.GameType }}</td>
              <td>{{ new Date(report.BetDetail.BetTime).toLocaleString() }}</td>
              <td>{{ report.BetDetail.BetAmount }}</td>
              <td>{{ report.BetDetail.ResultAmount }}</td>
              <td>{{ report.BetDetail.Balance }}</td>
              <td>
                <span
                  :class="
                    report.BetDetail.ResultAmount > 0 ? 'badge badge-success' : 'badge badge-danger'
                  "
                  >{{ report.BetDetail.ResultAmount > 0 ? "Win" : "Loser" }}</span
                >
              </td>
            </tr>
          </template>
          <template v-if="gameType == 2">
            <tr v-for="report in reports" :key="report.matchid">
              <td>{{ report.matchid }}</td>
              <td>{{ report.username }}</td>
              <td>{{ report.game_code }}</td>
              <td>{{ report.bet_time }}</td>
              <td>{{ report.bet }}</td>
              <td>{{ report.winlose }}</td>
            </tr>
          </template>
          <template v-if="gameType == 3">
            <tr v-for="report in reports" :key="report.matchid">
              <td>{{ report._id }}</td>

              <td>{{ report.GameBet_Type }}</td>
              <td>{{ report.GameBet_datetime }}</td>
              <td>{{ report.GameBet_Amount }}</td>
              <td>{{ report.GameBet_AmountWin }}</td>
              <td>
                <span
                  :class="report.GameBet_Status == 1 ? 'badge badge-success' : 'badge badge-danger'"
                  >{{ report.GameBet_Status == 1 ? "Lose" : "win" }}</span
                >
              </td>
            </tr>
          </template>
        </tbody>
        <tr v-if="totalPage > 1">
          <td colspan="5" class="text-right">
            <paginate
              v-model="page"
              :page-count="totalPage"
              :click-handler="handlePagination"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-class="'page-item'"
            ></paginate>
          </td>
        </tr>
      </table>

      <div v-else class="block-section">No win/loss report</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WinLossReportListItem",
  data: () => ({
    // reports: [],
    gameType: 0,
    subAccountId: "",
    fromDate: null,
    toDate: null
  }),
  created() {
    this.fetchReport();
  },
  computed: {
    ...mapGetters({
      reports: "wallet/History",
      totalPage: "wallet/TotalPages"
    }),
    hasItem() {
      if (this.reports) {
        return this.reports?.length > 0;
      }
    }
  },
  methods: {
    async fetchReport() {
      this.$store.dispatch("wallet/req_getHistoryGame", {
        page: this.page,
        fromDate: this.fromDate,
        toDate: this.toDate,
        game: this.gameType
      });
    },
    handlePagination() {
      this.fetchReport();
    },
    handleSearch() {
      this.fetchReport();
    }
  }
};
</script>
<style scoped>
.badge-success {
  background-color: green;
}
.badge-danger {
  background-color: red;
}
</style>
